import { DBFactory } from "~/classes/database/db_factory";
import { BaseModel } from "../base.model";
import {
  EducationResource,
  EducationResources,
} from "../education-resources/education-resource.model";

export type Classrooms = Classroom[];

export type ClassroomConstructorParams = {
  id?: string;
  externalId: string;
  name: string;
  section?: string;
  label: string;
  externalProvider: "sakai" | "google" | "canvas" | "iew";
  externalProviderUserId: string;
  userId: string;
  shouldHide?: boolean;
  lastSyncedTimestamp?: number;
  isArchived: boolean;
  classroomAssignmentGroups?: ClassroomAssignmentGroups;
  numGradedDocuments?: number;
  enableStudentReview?: boolean;
  enableClassSessions?: boolean;
  allowKWOSubmission: boolean;
  allowRoughDraftSubmission: boolean;
  displayOrder?: number;
  resources?: EducationResources;

  // If set to true, teacher will be probmpet to mark the final draft as  accept / incomplete instead of showing a grade to the student
  allowFinalDraftGradedState?: boolean;
};
export class Classroom extends BaseModel {
  id?: string;
  externalId: string;
  name: string = "";
  label: string = "";
  section?: string = "";
  externalProvider: "sakai" | "google" | "canvas" | "iew" = "iew";
  externalProviderUserId: string = "";
  userId: string = "";
  shouldHide?: boolean = false;
  lastSyncedTimestamp?: number;
  isArchived: boolean = false;
  classroomAssignmentGroups?: ClassroomAssignmentGroups;
  numGradedDocuments?: number;
  enableStudentReview: boolean;
  enableClassSessions: boolean;
  allowKWOSubmission?: boolean;
  allowRoughDraftSubmission?: boolean;
  allowFinalDraftGradedState?: boolean;
  displayOrder?: number;
  resources: EducationResources;

  constructor({
    id,
    externalId,
    name,
    label,
    section,
    externalProvider,
    externalProviderUserId,
    userId,
    shouldHide,
    lastSyncedTimestamp,
    isArchived,
    classroomAssignmentGroups,
    numGradedDocuments,
    enableStudentReview,
    enableClassSessions,
    allowKWOSubmission,
    allowRoughDraftSubmission,
    allowFinalDraftGradedState,
    displayOrder,
    resources,
  }: ClassroomConstructorParams) {
    super();

    this.id = id;
    this.externalId = externalId;
    this.name = name;
    this.label = label;
    this.section = section;
    this.externalProvider = externalProvider;
    this.externalProviderUserId = externalProviderUserId;
    this.userId = userId;
    this.shouldHide = shouldHide;
    this.lastSyncedTimestamp = lastSyncedTimestamp ?? 0;
    this.isArchived = isArchived ?? false;
    this.classroomAssignmentGroups = classroomAssignmentGroups ?? [];
    this.numGradedDocuments = numGradedDocuments;
    this.enableStudentReview = enableStudentReview ?? false;
    this.enableClassSessions = enableClassSessions ?? false;

    this.allowKWOSubmission = allowKWOSubmission ?? false;
    this.allowRoughDraftSubmission = allowRoughDraftSubmission ?? false;

    this.allowFinalDraftGradedState = allowFinalDraftGradedState ?? false;

    this.resources = resources ?? [];

    this.displayOrder = displayOrder ?? 0;

    this.modelDatabaseConfig = {
      collection: "/classrooms",
      path: `/classrooms/${this.id}`,
    };
  }

  get databaseConfig() {
    return {
      path: `classrooms/${this.id}`,
      collection: "classrooms",
    };
  }

  // Override toMap if you have special logic for converting to a map
  toMap(): ModelDatabaseData {
    return super.toMap();
  }

  static default(userId: string) {
    return new Classroom({
      name: "",
      label: "",
      externalId: "",
      externalProvider: "iew",
      externalProviderUserId: userId,
      userId: userId,
      shouldHide: false,
      isArchived: false,
      classroomAssignmentGroups: [],
      enableStudentReview: false,
      enableClassSessions: false,
      allowKWOSubmission: false,
      allowRoughDraftSubmission: false,
      resources: [],
    });
  }

  // Override fromMap if you have special logic for creating an instance from a map
  static fromMap(map: any): Classroom {
    if (map["resources"]) {
      map["resources"] = map["resources"].map((resource: any) =>
        EducationResource.fromMap(resource)
      );
    }

    return new Classroom(map);
  }

  async save() {
    const db = DBFactory.createDatabase();
    this.id = await db.save(this.toMap(), this.databaseConfig);
  }
}
